/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
  margin-top: 35px;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    list-style: none;
    align-items: center;
  }

  li {
    position: relative;
  }

  li + li {
    margin-left: 30px;
  }
  
  a, a:focus {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
    font-family: $font-secondary;
    font-size: 16px; 
    font-weight: 400;
    color: rgba(#fff, .7);
    white-space: nowrap;
    transition: 0.3s;

    i {
      font-size: 12px;
      line-height: 0;
      margin-left: 5px;
    }
  }

  a:before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -4px;
    left: 0;
    background-color: $primary;
    visibility: hidden;
    width: 0px;
    transition: all 0.3s ease-in-out 0s;
  }

  a:hover:before, li:hover > a:before, .active:before  {
    visibility: visible;
    width: 25px;
  }

  a:hover, .active, .active:focus, li:hover > a  {
    color: #fff;
  }

}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
  position: fixed;
  right: 15px;
  top: 15px;
}

@media (max-width: 991px) {

  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }

}

.navbar-mobile {

  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background:rgba(#000, .9);
  transition: 0.3s;
  z-index: 999;
  margin-top: 0;

  .mobile-nav-toggle {
    position: absolute;
    top: 15px;
    right: 15px;
  }

  ul {
    display: block;
    position: absolute;
    top: 55px;
    right: 15px;
    bottom: 45px;
    left: 15px;
    padding: 10px 0;
    overflow-y: auto;
    transition: 0.3s;
    border: 2px solid rgba(#fff, .20);
  }

  li {
    padding: 12px 20px;
  }

  li + li {
    margin: 0;
  }

  a, a:focus {
    font-size: 16px; 
    position: relative;
  }

}
