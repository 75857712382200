/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: ease-in-out 0.3s;
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  z-index: 997;
  overflow-y: auto;

  * {
    transition: ease-in-out 0.3s;
  }

  h1 {
    font-size: 48px;
    margin: 0;
    padding: 0;
    line-height: 1;
    font-weight: 700;
    font-family: $font-secondary;
  }

  h1 a, h1 a:hover {
    color: #fff;
    line-height: 1;
    display: inline-block;
  }

  h2 {
    font-size: 24px;
    margin-top: 20px;
    color: rgba(#fff, .8);
    
    span {
      color: #fff;
      border-bottom: 2px solid $primary;
      padding-bottom: 6px;
    }
  }

  img {
    padding: 0;
    margin: 0;
  }

  .social-links {
    margin-top: 40px;
    display: flex;
    a {
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(#fff, 1);
      color: #fff;
      line-height: 1;
      margin-right: 8px;
      border-radius: 50%;
      width: 40px;
      height: 40px;
      &:hover {
        background: $primary;
      }
    }
  }

  @media (max-width: 992px) {

    h1 {
      font-size: 36px;
    }

    h2 {
      font-size: 20px;
      line-height: 30px;
    }

    .social-links {
      margin-top: 15px;
    }
    .container {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

}


/* Header Top */
#header.header-top {
  height: 80px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background: rgba(#000, .9);

  .social-links, h2 {
    display: none;
  }

  h1 {
    margin-right: auto;
    font-size: 36px;
  }

  .container {
    display: flex;
    align-items: center;
  }

  .navbar {
    margin: 0;
  }

  @media (max-width: 768px) {

    height: 60px;
    h1 {
      font-size: 26px;
    }
  }

}
